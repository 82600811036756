<template>
  <div>
    <router-view />
    <van-overlay :show="loading">
      <div class="loading">
        <img
          src="@/assets/icons/loading_icon.png"
          alt=""
          srcset="@/assets/icons/loading_icon@2x.png 2x @/assets/icons/loading_icon@3x.png 3x"
        />
        <div class="loading-text">加载中</div>
      </div>
    </van-overlay>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import {
  useGlobalQueryLoading,
  useGlobalMutationLoading
} from '@vue/apollo-composable'

export default defineComponent({
  setup() {
    const queryLoading = useGlobalQueryLoading()
    const mutationLoading = useGlobalMutationLoading()
    const loading = computed(() => queryLoading.value || mutationLoading.value)
    return {
      loading
    }
  }
})
</script>
<style scoped lang="scss">
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
.loading {
  width: 136px;
  height: 136px;
  background: #2a3148;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  img {
    width: 38px;
    transform: rotate(360deg);
    animation: rotation 1.4s linear infinite;
    -moz-animation: rotation 1.4s linear infinite;
    -webkit-animation: rotation 1.4s linear infinite;
    -o-animation: rotation 1.4s linear infinite;
  }
  .loading-text {
    color: rgba(255, 255, 255, 0.9);
    margin-top: 16px;
  }
}
</style>
