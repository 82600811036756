<template>
  <Transition v-show="visible && horizontal" name="down">
    <div class="message" :style="style[type]">
      <img class="iconfont" :src="style[type].icon" />
      <span class="text">{{ text }}</span>
    </div>
  </Transition>
  <Transition v-show="visible && !horizontal">
    <div class="message2">
      <img :src="style2[type].icon" alt="" />
      <div>{{ text }}</div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import warn from '@/assets/icons/warning-toast.svg'
import error from '@/assets/icons/error-toast.svg'
import success from '@/assets/icons/success-toast.svg'
import failed from '@/assets/icons/warning-toast.svg'
import dui from '@/assets/icons/icon_dui.svg'
type StyleType = {
  [propName: string]: {
    [propName: string]: string
  }
}
export default defineComponent({
  name: 'Message',
  props: {
    type: {
      type: String,
      default: 'warn'
    },
    text: {
      type: String,
      default: ''
    },
    direction: {
      type: String,
      default: 'horizontal'
    }
  },
  setup(props) {
    const style: StyleType = {
      warn: {
        icon: warn,
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgb(42, 49, 72)',
        borderColor: '#2A3148'
      },
      error: {
        icon: error,
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgb(42, 49, 72)',
        borderColor: '#2A3148'
      },
      success: {
        icon: success,
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgb(42, 49, 72)',
        borderColor: '#2A3148'
      }
    }
    const style2: StyleType = {
      warn: {
        icon: failed,
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgb(42, 49, 72)',
        borderColor: '#2A3148'
      },
      success: {
        icon: dui,
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgb(42, 49, 72)',
        borderColor: '#2A3148'
      }
    }
    const horizontal = computed(() => props.direction === 'horizontal')
    // 控制元素显示隐藏
    const visible = ref(false)
    onMounted(() => {
      visible.value = true
    })
    return { style, style2, horizontal, visible }
  }
})
</script>

<style scoped lang="scss">
.message {
  position: fixed;
  width: 65%;
  left: 50%;
  transform: translate(-50%, 0%);
  // padding: 0 50px;
  padding: 0 16px;
  top: 60px;
  z-index: 9999999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  animation: down 0.3s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

.message .iconfont {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.message .text {
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
}
.message2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding: 0 16px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 136px;
  height: 136px;
  background-color: #2a3148;
  border-radius: 8px;
  img {
    width: 40px;
    margin-bottom: 16px;
  }
  > div {
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.2;
  }
}
// @keyframes down {
//   0% {
//     transform: translateY(-100%);
//   }
//   100% {
//     transform: translateY(0);
//   }
// }
</style>
