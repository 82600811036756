import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/index.vue')
  },
  {
    path: '/currency',
    name: 'currency',
    component: () => import('../views/currency/index.vue')
  },
  {
    path: '/bank',
    name: 'bank',
    component: () => import('../views/bank/index.vue')
  },
  {
    path: '/deposit-mode',
    name: 'deposit-mode',
    component: () => import('../views/deposit-mode/index.vue')
  },
  {
    path: '/authorization',
    name: 'authorization',
    component: () => import('../views/authorization/index.vue')
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('../views/apply/index.vue')
  },
  {
    path: '/status/:id',
    name: 'status',
    component: () => import('../views/status/index.vue')
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: () => import('../views/deposit/index.vue')
  },
  {
    path: '/deposit-status',
    name: 'deposit-status',
    component: () => import('../views/deposit-status/index.vue')
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('../views/record/index.vue')
  },
  {
    path: '/preview',
    name: 'preview',
    component: () => import('../views/preview/index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
