import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  from
} from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import signWrap from '@/apollo/sign'
import { Message } from '@/components/Message'
import redirectUrl from '@/utils/redirect-url'

const cache = new InMemoryCache()

const baseLink = new HttpLink({
  uri: import.meta.env.VITE_APP_BASE_API
})

const dzmLink = new HttpLink({
  uri: import.meta.env.VITE_APP_BASE_API
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((item: any) => {
      console.log(item)
      switch (item.err) {
        case 400:
          Message({
            direction: 'vertical',
            text: '请求异常，请稍后重试！(code: 400)'
          })
          break
        case 542:
          Message({
            direction: 'vertical',
            text: '请求异常，请稍后重试！(code: 542)'
          })
          break
        case 543:
          Message({ direction: 'vertical', text: '长时间未操作，请重新登录！' })
          if (!window.GH_NATIVE_SDK.isInAppWebview()) {
            redirectUrl()
          }
          break
        case 544:
          Message({
            direction: 'vertical',
            text: '请求异常，请稍后重试！(code: 544)'
          })
          break
        case 546:
          Message({
            direction: 'vertical',
            text: '请求异常，请稍后重试！(code: 546)'
          })
          break
        case 547:
          Message({
            direction: 'vertical',
            text: '请求异常，请稍后重试！(code: 547)'
          })
          break
        case 545:
          Message({ direction: 'vertical', text: '长时间未操作，请重新登录！' })
          if (!window.GH_NATIVE_SDK.isInAppWebview()) {
            redirectUrl()
          }
          break
        default:
          Message({ direction: 'vertical', text: '请求异常，请稍后重试！' })
          break
      }
    })
  }
  // 网络错误处理
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const additiveLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    headers: {
      'content-type': 'application/json',
      ...signWrap(operation)
    }
  }))

  return forward(operation).map(data => {
    console.log(data.data)
    return data
  })
})

export const apolloClient = new ApolloClient({
  cache,
  link: from([additiveLink, errorLink, baseLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    }
  }
})

export const dmzClient = new ApolloClient({
  cache,
  link: from([additiveLink, errorLink, dzmLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    }
  }
})
